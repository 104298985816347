:root {
    --background: #FFFFFF;
    --yellow: #F5C32C;
    --orange: #FCA61F;
    --black: #242D49;
    --gray: #788097;
    --light: #f2f3f5;
    --blueCard: #DDF8FE;
    --purple: rgb(177, 5, 148);
    --boxShadow: 0px 19px 60px rgb(0 0 0 / 8%);
    --orangeCard: rgba(252, 166, 31, 0.45);
    --smboxShadow: -79px 51px 60px rgba(0, 0, 0, 0.08);
}

.App {
    padding: 0.5rem 3.5rem;
    overflow: hidden;
    color: var(--black);
}

.button {
    background: linear-gradient(180deg, #fdc50f 26.71%, #fb982f 99.36%);
    box-shadow: 0px 20px 24px 3px rgba(251, 161, 40, 0.42);
    border-radius: 34px;
    border: none;
    color: white;
    font-size: 16px;
    padding: 11px 26px 11px 26px;
}

.button:hover {
    background: linear-gradient(180deg, #fdc50f 99.36%, #fb982f 26.71%);
    cursor: pointer;
    border: 1px solid var(--orange);
    color: var(--orange);
}

@media screen and (max-width: 480px) {
    .App {
        padding: 0.5rem 1rem;

    }
}

p {
    color: var(--black);
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.modal-close-button {
    top: 50%;
    right: 80px;
    width: 5rem;
    height: 5rem;
    transform: translateY(-50%);
    z-index: 9;
    animation: 1s fadeIn ease-in-out;
}

.modal-close-button i {
    line-height: 1.5;
    color: white !important;
}

.pill {
    background: #0563bb !important;
    border-radius: 10px;
    margin-right: 5px;
    color: #fff;
}

.m-zero {
    margin: 0 !important;
}

.btn-outline-dark:hover {
    background: var(--orange);
    border-color: var(--orange);
}

@keyframes fadeIn {
    0% {
        opacity: 0;
        right: 0;
    }
    25% {
        opacity: 0;
        right: 60px;
    }
    100% {
        opacity: 1;
        right: 80px;
    }
}

.sweet-alert {
    background: var(--background) !important;
}
