.nav h3 {
    color: var(--orange);
    font-size: 1.5rem;
}

.switch {
    position: relative;
    cursor: pointer;
    margin-top: -8px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
}

.switch p i {
    font-size: 1.5rem;
    color: var(--orange);
}

.switch-checkbox {
    position: absolute;
    opacity: 0;
    pointer-events: none;
}

.switch-label {
    display: block;
    overflow: hidden;
    cursor: pointer;
    height: 1.5rem;
    border: 2px solid var(--orange);
    border-radius: 24px;
    transition: background-color 0.3s ease-in;
}

.switch-label:before {
    content: "";
    display: block;
    width: 1.5rem;
    margin: 0;
    background: var(--orange);
    position: absolute;
    top: 0;
    bottom: 0;
    right: 2.5rem;
    border: 2px solid var(--orange);
    border-radius: 30px;
    transition: all 0.3s ease-in 0s;
}

.switch-checkbox:checked + .switch-label {
    background-color: var(--orange);
}

.switch-checkbox:checked + .switch-label, .switch-checkbox:checked + .switch-label:before {
    border-color: var(--orange);
}

.switch-checkbox:checked + .switch-label:before {
    background-color: var(--light);
}

.switch-checkbox:checked + .switch-label:before {
    right: 0;
}

.hero-wrapper {
    height: 90vh;
}

.hero-wrapper .left h1 {
    font-size: 5rem;
    font-family: Nunito;
    font-weight: bold;
    color: var(--orange);
}

.hero-wrapper .left p {
    font-family: Nunito;
    font-size: 2rem;
}

.hero-particles {
    filter: drop-shadow(10px 10px 10px rgb(0 0 0 / 0.14)) drop-shadow(-10px -10px 10px rgb(0 0 0 / 0.14));
    /*animation: floating 15s cubic-bezier(0.32, 0, 0.67, 0) 0s infinite normal forwards;*/
}

.hero-wrapper .right .purple-1 {
    position: absolute;
    top: 15%;
    left: 40%
}

.hero-wrapper .right .purple-2 {
    position: absolute;
    bottom: 12%;
    right: 15%
}

.hero-wrapper .right .orange {
    position: absolute;
    right: 12%;
    top: 15%
}

.hero-profile-picture {
    position: absolute;
    top: 15%;
    filter: drop-shadow(10px 10px 10px rgb(0 0 0 / 0.14)) drop-shadow(-10px -10px 10px rgb(0 0 0 / 0.14));
    animation: float 6s ease-in-out infinite;
}

.hero-profile-picture .bg-blur {
    top: 78px;
    opacity: 0.25;
    filter: blur(25px);
    box-shadow: 25px 25px 20px rgb(0 0 0 / 50%);
}

.hero-profile-picture img {
    position: absolute;
}

.hero-profile-picture img:nth-child(1) {
    top: 78px;
}

.blur-shadow {
    position: absolute;
    width: 10rem;
    height: 10rem;
    opacity: 0.35;
    filter: blur(3rem);
    z-index: -9;
}

.shadow-1 {
    background: var(--orange);
    bottom: 20%;
    left: 45%;
}

.shadow-2 {
    background: var(--purple);
    top: 23%;
    left: 40%;
}

.hero-wrapper .social-links {
    margin-top: 30px;
}

.hero-wrapper .social-links a {
    font-size: 24px;
    display: inline-block;
    color: #45505b;
    line-height: 1;
    margin-right: 20px;
    transition: 0.3s;
}
.hero-wrapper .social-links a:hover {
    color: var(--orange);
}

@keyframes float {
    0% {
        transform: translatey(0px);
    }
    50% {
        transform: translatey(-20px);
    }
    100% {
        transform: translatey(0px);
    }
}

@keyframes floating {
    0% {
        transform: translate(0);
    }

    10% {
        transform: translate(-2px, -2px) rotate(3deg);
    }

    20% {
        transform: translate(2px, -2px);
    }

    30% {
        transform: translate(-2px, 2px) rotate(-3deg);
    }

    40% {
        transform: translate(2px, 2px);
    }

    50% {
        transform: translate(-2px, -2px);
    }

    60% {
        transform: translate(2px, -2px);
    }

    70% {
        transform: translate(-2px, 2px);
    }

    80% {
        transform: translate(-2px, -2px);
    }

    90% {
        transform: translate(2px, -2px);
    }

    100% {
        transform: translate(0);
    }
}


